<template>
  <b-card class="w-100 facilities">
    <b-card-title>Tags</b-card-title>
    <b-card-sub-title>
      This section is for the facilities that are available in your property. These facilities will be seen by guests when they search for a place to stay.
    </b-card-sub-title>
    <b-card-body>
      <b-row
        align-h="between"
        class="facility-names mb-2"
      >
        <b-col
          v-for="tag in tags"
          :key="tag.id"
          md="4"
          class="room-info"
        >
          <div class="services">
            <b-form-checkbox
              :id="`${tag.id}`"
              :key="tag.id"
              v-model="selectedTags"
              :value="tag.id"
              class="my-1"
            >
              {{ tag.text }}
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <div class="text-right">
        <b-button
          variant="primary"
          :disabled="isBtnLoading"
          @click="updateTagList"
        >
          <b-spinner
            v-if="isBtnLoading"
            small
          />
          Submit
        </b-button>
      </div>
    </b-card-body>
    <div
      class="text-right p-2 border-top"
    >
      <b-button
        variant="outline-primary"
        :to="`/property-register/step-3/${tourId}`"
      >
        Photos
        <feather-icon
          icon="ArrowRightIcon"
          size="12"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'Tags',
  mixins: [helper],
  data() {
    return {
      isBtnLoading: false,
      searchQuery: '',
      tags: '',
      selectedTags: [],
      tourId: '',
    }
  },
  mounted() {
    this.tourId = this.$route.params.id
    this.getTourData()
    this.getTagList()
  },
  methods: {
    getTourData() {
      api.getData(`tour/admin_services/tour/${this.$route.params.id}/`, true)
        .then(response => {
          if (response.data.status) {
            this.selectedTags = response.data.result.tags.map((tour => tour.id))
          }
        }).catch().finally(() => {
        })
    },
    getTagList() {
      api.getData('tour/admin_services/tour_tag_list/', true)
        .then(response => {
          if (response.data.status) {
            this.tags = response.data.result
          }
        }).catch().finally(() => {
        })
    },
    updateTagList() {
      this.isBtnLoading = true
      api.updateData(`tour/admin_services/tour/${this.$route.params.id}/tour_update/`, true, { tags: this.selectedTags }).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'policy updated successfully')
          this.getTourData()
          this.getTagList()
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@/assets/scss/tour.scss';
</style>
